import React from 'react'

const Card = ({
  Icon,
  title,
  description,
  numberOfItems = 3,
  CallToAction = null,
}: Props) => {
  return (
    <div className={`w-full text-center mb-10 md:w-1/${numberOfItems} md:mb-0`}>
      <div className="bg-white text-gray-800 p-8 md:px-3 md:mx-2 h-full rounded">
        {Icon}
        <h3 className="text-xl mb-2 font-medium">{title}</h3>
        <p className={CallToAction ? 'mb-5' : null}>{description}</p>
        {CallToAction}
      </div>
    </div>
  )
}

interface Props {
  Icon?: JSX.Element
  title: string
  description: string
  numberOfItems?: number
  CallToAction?: () => JSX.Element
}

export default Card
