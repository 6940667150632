import React from 'react'

import { Email } from '../ContactOptions'

const steps = [
  {
    title: 'Tipp 1: Frivissa ist perfekt',
    description:
      'Walter Lutz, Stadlwirt aus Nauders: „Fürs Schnitzelbacken gibt es nichts Besseres als Frivissa für mich, denn es fördert den Eigengeschmack ganz besonders."',
  },
  {
    title: 'Tipp 2: Das frische Fleisch',
    description:
      'Beim Fleisch kommt nur die frischeste Ware in Frage! Vorsichtig klopfen, nur so dünn, dass keine Löcher entstehen. Salzen und sparsam pfeffern – das genügt!',
  },
  {
    title: 'Tipp 3: Die feine Panade',
    description:
      'Feinstes Weizenmehl, das Ei nicht fest verquirlen sondern nur mit dem Schneebesen mehrmals leicht durchziehen. Die Brösel nicht zu fest andrücken, dann wird die Panade goldrichtig!',
  },
  {
    title: 'Tipp 4: Das richtige Backen',
    description:
      'Bei nicht zu hoher Temperatur und ausreichend Frivissa-Öl oder Frivissa-Fett das Schnitzel mehrmals wenden, bis es eine goldgelbe Farbe hat.',
  },
  {
    title: 'Tipp 5: Das perfekte Schnitzel',
    description:
      'Nach dem Backen vorsichtig das Schnitzel auf Küchenpapier abtropfen lassen. Auf einem vorgewärmten Teller anrichten, mit Zitronenspalten und Petersilie garnieren!',
  },
]

const SchnitzelReceipt = () => {
  return (
    <section className="text-center py-10">
      <div className="container">
        <h2 className="text-2xl">So gelingt ihr Schnitzel sicher!</h2>
        <hr className="mx-auto my-2" />
        <div className="max-w-2xl mx-auto text-left">
          {steps.map(step => {
            const { title, description } = step

            return (
              <div key={title} className="mb-2">
                <h3 className="text-lg">{title}</h3>
                <hr className="my-2" />
                <p>{description}</p>
                <br />
              </div>
            )
          })}
          <p>
            Verraten Sie uns IHREN Tipp für perfekte Sonntagsschnitzel?
            Schreiben Sie uns ein E-Mail an <Email className="golden" />
          </p>
        </div>
      </div>
    </section>
  )
}

export default SchnitzelReceipt
