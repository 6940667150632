import React from 'react'

import Card from '../Card'

const breadings = [
  {
    title: 'Cornflakes',
    description:
      'Ersetzen Sie die Semmelbrösel einfach einmal durch Cornflakes. Achtung: Achten Sie darauf, dass das Frivissa-Öl oder -Fett beim Backen nicht allzu heiß ist!',
    img: '/uploads/frivissa/cornflakes.jpg',
  },
  {
    title: 'Kürbiskerne',
    description:
      'Mischen Sie Semmelbrösel mit der gleichen Menge grob geriebener Kürbiskerne und überraschen Sie Ihre Familie mit einem knusprigen Kürbiskern-Schnitzel.',
    img: '/uploads/frivissa/kuerbiskerne.jpg',
  },
  {
    title: 'Kokosraspeln',
    description:
      'Eine nicht ganz alltäglich Variation, die begeistern wird: Vermischen Sie Semmelbrösel und Kokosraspeln im Verhältnis 1:2 (z.B. 2 EL Semmelbrösel, 4 EL Kokosraspeln).',
    img: '/uploads/frivissa/kokos.jpg',
  },
]

const supplements = [
  {
    title: 'Kartoffelsalat',
    description:
      'Der originale Alt-Wiener-Kartoffelsalat besteht aus Kartoffeln und Zwiebeln und einem Dressing aus Essig, Öl, Rindsuppe (oder klarer Gemüsesuppe) und Senf. Servieren Sie ihn mit frischem Schnittlauch!',
    img: '/uploads/frivissa/kartoffelsalat.jpg',
  },
  {
    title: 'Reis und Erbsen',
    description:
      'Reis und Erbsen gehören bei Groß und Klein zu den beliebtesten Beilagen. Ein Tipp für ein besonders frisches Grün der Erbsen: Geben Sie etwas Zucker ins Kochwasser!',
    img: '/uploads/frivissa/reis.jpg',
  },
  {
    title: 'Pommes',
    description:
      'Legen Sie die noch rohen Kartoffelstreifen vor dem Frittieren etwa 15 Minuten in Wasser, damit die Stärke austritt. Dann werden die Pommes noch knuspriger!',
    img: '/uploads/frivissa/pommes.jpg',
  },
]

const Section = ({ title, description, cards }: SectionProps) => {
  return (
    <section className="bg-gray-900 text-center py-10">
      <div className="container">
        <h2 className="text-2xl">{title}</h2>
        <hr className="mx-auto my-2" />
        <p className="max-w-2xl mx-auto mt-2 mb-5">{description}</p>
        <div className="flex flex-wrap">
          {cards.map(card => {
            const { title, description, img } = card

            return (
              <Card
                key={title}
                title={title}
                description={description}
                Icon={
                  <img src={img} alt={title} className="rounded mx-auto mb-5" />
                }
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

interface SectionProps {
  title: string
  description: string
  cards: { title: string; description: string; img: string }[]
}

const BreadingsAndSupplements = () => {
  return (
    <div className="bg-gray-900 text-white">
      <Section
        title="Traumhaft knusprige Panaden"
        description="Weg von den Konventionen und rein ins Kochvergnügen! Probieren Sie
            eine dieser drei exotischen Panaden."
        cards={breadings}
      />
      <Section
        title="Perfekte Beilagen"
        description="Was wäre ein Schnitzel ohne seine Beilagen? Benutzen Sie diese Tipps
        um sie noch besser zu machen."
        cards={supplements}
      />
    </div>
  )
}

export default BreadingsAndSupplements
