import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import YoutubeVideo from '../components/YoutubeVideo'
import FrivissaProducts from '../components/Frivissa/FrivissaProducts'
import BreadingsAndSupplements from '../components/Frivissa/BreadingsAndSupplements'
import SchnitzelReceipt from '../components/Frivissa/SchnitzelReceipt'

const title = 'Frivissa | Stadlwirt'

const FrivissaPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(
          relativePath: { eq: "traditional-food.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} />
      <HeroHeader
        title="Frivissa"
        subTitle="Mein starker Partner in der Küche"
        fluidBackgroundImage={fluidBackgroundImage}
        staticBackgroundImage="/uploads/traditional-food.jpg"
      />
      <section className="py-10 text-center">
        <div className="container">
          <h2 className="text-2xl">Für meine Schnitzel nur das Beste!</h2>
          <hr className="mx-auto my-2" />
          <p className="max-w-2xl mx-auto mb-10">
            Durch meine langjährige Erfahrung in der Gastronomie habe ich schon
            vieles probiert. Mein Fazit: Mit Frivissa gelingt&apos;s mir einfach
            am Besten!
            <br />
            <br />
            <a
              href="https://www.frivissa.at"
              target="_blank"
              rel="noopener noreferrer"
              className="golden"
            >
              www.frivissa.at
            </a>
          </p>
          <YoutubeVideo className="max-w-3xl mx-auto" videoId="V9Su6JBNUZk" />
        </div>
      </section>

      <FrivissaProducts />

      <SchnitzelReceipt />

      <BreadingsAndSupplements />

      <section className="text-center py-10">
        <div className="container">
          <h2 className="text-2xl">Frivissa in Ihrer Küche nutzen</h2>
          <hr className="mx-auto my-2" />
          <p className="max-w-2xl mx-auto my-4">
            <a
              href="https://frivissa.at/anfrage.html"
              target="_blank"
              rel="noopener noreferrer"
              className="golden"
            >
              Treten Sie mit Frivissa in Kontakt
            </a>{' '}
            und finden Sie bei der individuellen Beratung heraus, welche
            Produtke für Sie in Frage kommen. <br /> <br /> Der Stadlwirt
            wünscht gutes Gelingen und freut sich über Erfahrungsberichte und
            Rezepte, bei denen Sie besonders köstliche Speisen mit Hilfe von
            Frivissa herbeigezaubert haben!
          </p>
        </div>
      </section>
    </>
  )
}

export default FrivissaPage
