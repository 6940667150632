import React from 'react'

import Card from '../Card'

import Sustainable from '../../svg/sustainable.svg'
import Pan from '../../svg/fried.svg'
import CheckMark from '../../svg/checked.svg'
import Economic from '../../svg/economic.svg'

const products = [
  {
    title: 'Frittieröl',
    description: 'Kurze Beschreibung des Frittieröls.',
    img: '/uploads/frivissa/csm_frittieroel_d75982b417.png',
  },
  {
    title: 'Pflanzenfett',
    description: 'Kurze Beschreibung des Pflanzenfetts.',
    img: '/uploads/frivissa/csm_pflanzenfett_01190509ee.png',
  },
  {
    title: 'Frittierfett',
    description: 'Kurze Beschreibung des Frittierfetts.',
    img: '/uploads/frivissa/csm_frittierfett-3l_86a6d14b60.png',
  },
]

const features = [
  {
    title: 'Hitzeresistent',

    description: 'Auch unter großer Hitze funktioniert das Fett hervorragend!',
    Icon: Pan,
  },
  {
    title: 'Wirtschaftlich',
    description:
      'Bis zu 3 Mal längere Lebensdauer als vergleichbare Produkte. Sparen Sie bares Geld!',
    Icon: Economic,
  },
  {
    title: 'Geruchsneutral',
    description:
      'Geschmacks- und Geruchsneutral, um Ihre kulinarischen Fähigkeiten optimal zum Ausdruck zu bringen.',
    Icon: CheckMark,
  },
  {
    title: 'Nachhaltig',
    description:
      'Frivissa verwendet ausschließlich hochwertige Rohstoffe und natürlich Zutaten.',
    Icon: Sustainable,
  },
]

const FrivissaProducts = () => {
  return (
    <section className="bg-gray-900 py-10 text-center text-white">
      <div className="container">
        <h2 className="text-2xl">
          Das beste Speiseöl und Frittierfett fürs Backen, Braten und
          Frittieren!
        </h2>
        <hr className="mx-auto my-2" />
        <p className="max-w-2xl mx-auto my-4">
          Wer mit Liebe kocht, legt Wert auf beste Zutaten. Darum besteht
          Frivissa Speiseöl aus wertvollen pflanzlichen Fetten und Ölen und ist
          komplett frei von Zusatzstoffen und Geschmacksverstärkern! <br />
          <br />
          Ob Schnitzel, vegetarische Spezialität, vegane Speise oder Kuchen –
          mit Frivissa genießen Sie die ganze Welt der modernen Küche mit gutem
          Gewissen und das ohne störenden Nebengeschmack oder Geruch.
        </p>

        <div className="flex flex-wrap my-8">
          {products.map(product => {
            const { title, description, img } = product

            return (
              <div
                key={title}
                className="w-full text-center px-1 py-2 mb-10 md:w-1/3 md:mb-0"
              >
                <div className="bg-gray-200 text-gray-800 p-8 md:px-3">
                  <img src={img} alt={title} className="mx-auto mb-5" />
                  <h3 className="text-xl mb-2 font-medium">{title}</h3>
                  <p>{description}</p>
                </div>
              </div>
            )
          })}
        </div>

        <h2 className="text-2xl">Was spricht für Frivissa?</h2>
        <hr className="mx-auto my-2" />
        <div className="flex flex-wrap py-4">
          {features.map(feature => {
            const { title, description, Icon } = feature

            return (
              <Card
                key={title}
                Icon={
                  <Icon
                    className="rounded mx-auto mb-5"
                    style={{ maxWidth: '9rem', maxHeight: '9rem' }}
                  />
                }
                title={title}
                description={description}
                numberOfItems={4}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default FrivissaProducts
